import { promises as fs } from 'fs';
import path from 'path';
import { default as matter } from 'gray-matter';
import { Container } from '@components/layout/Grid';
import MetaTags from '@components/MetaTags';
import * as Sentry from '@sentry/nextjs';
import { ENABLED_LOCALS } from '@lib/constants';

const page = ({ pageProps }) => {
  const { content, metaData } = pageProps;

  return (
    <div className="text-base px-5">
      <MetaTags
        title={metaData.title}
        description={metaData.desc}
        thumbnailUrl={metaData.image}
      />
      <Container
        id="yg-content"
        className="font-light flex-col bg-[#f2f1f1]"
        dangerouslySetInnerHTML={{ __html: content }}
      ></Container>
    </div>
  );
};

const isValidSlug = (slug) => /^[a-zA-Z0-9_-]+$/.test(slug);

export const getServerSideProps = async (ctx) => {
  const { lang, params } = ctx.query;

  // WE REDIRECT FROM _APP BUT NEXT STILL CONTINUES RENDERING
  // THE COMPONENT/PAGE WHILE HEADERS ARE SENT TO THE BROWSER FOR REDIRECT
  if (!ENABLED_LOCALS.includes(lang)) {
    return {
      props: {},
    };
  }

  try {
    const filename = params.join('_');

    if (!isValidSlug(filename)) {
      return {
        notFound: true,
      };
    }

    const filePath = path.join(
      process.cwd(),
      `/legacy_landing_pages/landing_pages/${lang}_${filename}.md`
    );

    const fileContent = await fs.readFile(filePath, 'utf8');

    const matterContent = matter(fileContent);

    return {
      props: {
        // because of prettier hook, the html will be prettified everytime, so we minify on request
        content: matterContent.content,
        metaData: matterContent.data,
      },
    };
  } catch (ex) {
    Sentry.captureException(ex);

    return {
      notFound: true,
    };
  }
};

page.isLegacyPage = true;

export default page;
